<template>
    <div class="edit-profile-detail">
        <div class="title" v-html="$translate(type.toUpperCase())" />
        <VerticalOptions @select="onClickItem" :optionItems.sync="profileOption" :matchUnit="'name'" />
        <div v-if="needHouseOnwer">
            <div class="item-title">소유자 명의</div>
            <HorizontalOptions
                v-if="needHouseOnwer"
                @select="onClickItem"
                :optionItems.sync="houseOwnerOptions"
                :matchUnit="'name'"
            />
        </div>
    </div>
</template>

<script>
import VerticalOptions from './VerticalOptions'
import HorizontalOptions from './HorizontalOptions'

export default {
    name: 'House',
    props: {
        type: String,
        section: String,
        stage: Number,
    },
    data: () => ({
        profileOption: null,
        houseOwnerOptions: null,
    }),
    components: {
        VerticalOptions,
        HorizontalOptions,
    },
    watch: {
        stage: function () {
            this.init()
        },
    },
    computed: {
        profile() {
            return this.$store.getters.profile || {}
        },
        needHouseOnwer() {
            if (this.type === 'house_payment' && this.profileOption.length) {
                return this.profileOption.some(p => p.id === 189 && p.$$selected)
            } else {
                return false
            }
        },
    },
    mounted() {
        this.init()
        this.getHouseOwnerOptions()
    },
    methods: {
        init() {
            const optionType = this.$case.toCamel(this.type)
            this.profileOption = this.$store.getters[optionType]
            this.profileOption = this.profileOption.map(option => {
                if (option.name === this.profile[this.type]) {
                    option.$$selected = true
                } else {
                    option.$$selected = false
                }
                return option
            })
        },

        onClickItem(val) {
            if (val.id === 189) {
            } else {
                this.$emit('close')
            }
        },
        getHouseOwnerOptions() {
            this.houseOwnerOptions = Object.keys(this.$profile.houseOwner).map(key => {
                const item = this.$profile.houseOwner[key]
                if (item.id === this.profile.house_owner) {
                    item.$$selected = true
                } else {
                    item.$$selected = false
                }
                return item
            })
        },
        setPrivacySetting() {
            const privacy = this.profile.privacy_setting
            privacy.show_house_owner = 1
            privacy.show_house_payment = 1
            privacy.show_house_style = 1
            this.$profile.setPrivacySettings(privacy)
        },
        onSave(key) {
            const selectedItem = this.profileOption.find(o => o.$$selected)
            const selectedHouseOwner = this.houseOwnerOptions.find(o => o.$$selected)

            this.$set(this.profile, key, selectedItem.name)
            this.$profile.updateProfileOptions(key, selectedItem)

            if (this.needHouseOnwer) {
                this.$set(this.profile, 'house_owner', selectedHouseOwner.id)
                this.setPrivacySetting()
            } else {
                this.$set(this.profile, 'house_owner', null)
            }
        },
    },
}
</script>

<style scoped lang="scss">
.item-title {
    margin: 0 16px;
    font-size: 16px;
    line-height: 26px;
}
</style>
