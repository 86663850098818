<template>
    <div class="vertical-options">
        <div
            class="option-item"
            v-for="(item, idx) in optionItems"
            :key="idx"
            @click="onClickItem(item, idx)"
            :class="{ selected: item.$$selected }"
        >
            {{ item.title || item.name }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'VerticalOptions',
    props: {
        optionItems: {
            type: Array,
        },
        matchUnit: {
            type: String,
            default: 'id',
        },
    },

    data: () => ({
        selectedIdx: null,
    }),
    computed: {
        profile() {
            return this.$store.getters.profile || {}
        },
    },
    methods: {
        async onClickItem(item, idx) {
            const updateItem = this.optionItems.map(oItem => {
                if (oItem[this.matchUnit] === item[this.matchUnit]) {
                    oItem.$$selected = true
                } else {
                    oItem.$$selected = false
                }
                return oItem
            })
            await this.updateItem(updateItem)
            await this.saveItem(item)
        },
        updateItem(updateItem) {
            this.$emit('update:optionItems', updateItem)
        },
        saveItem(item) {
            this.$emit('select', item)
        },
    },
}
</script>

<style scoped lang="scss">
.vertical-options {
    padding: 16px;
    .option-item {
        border-radius: 8px;
        border: 1px solid $grey-02;
        width: 100%;
        @include center;
        height: 48px;
        padding: 8px 16px;
        margin: 8px 0;

        &.selected {
            border: 1px solid $blue-primary;
            color: $blue-primary;
        }
    }
}
</style>
